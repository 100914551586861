import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const ACRLDPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: file(relativePath: { eq: "icon.png" }) {
        publicURL
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(
        slug: { eq: "air-conditioning-re-gasing-and-leak-detection" }
      ) {
        slug
        airConditioningFields {
          airConditioningBanner {
            airConditioningBannerHeading
            airConditioningBannerBackgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          airConditioningContentSection {
            airConditioningContentHeading
            airConditioningContentLeft
            airConditioningContentRight
          }
          airConditioningForm {
            airConditioningFormHeading
          }
          airConditioningLeftImageRightContent {
            airConditioningRightContentHeading
            airConditioningRightContentText
            airConditioningRightContentButton {
              title
              target
              url
            }
            airConditioningLeftImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          airConditioningReviews {
            airConditioningReviewsHeading
          }
          airConditioningServicesCards {
            airConditioningServicesCardsHeading
            airConditioningServicesCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, airConditioningFields, slug },
  } = data;

  const {
    airConditioningBanner,
    airConditioningContentSection,
    airConditioningForm,
    airConditioningLeftImageRightContent,
    airConditioningReviews,
    airConditioningServicesCards,
  } = airConditioningFields;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
    mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    description: `${seoFields?.metaDescription}`,
    logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
    name: "ECF Car Care",
    url: "https://www.ecfcarcare.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
      image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
      name: "ECF Car Care",
      url: "https://www.ecfcarcare.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.ecfcarcare.co.uk/reviews",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "ECF Car Care",
        sameAs: "https://www.ecfcarcare.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "What We Do",
        item: {
          url: `${siteUrl}/what-we-do`,
          id: `${siteUrl}/what-we-do`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Air Conditioning Re-gasing And Leak Detection",
        item: {
          url: `${siteUrl}/what-we-do/air-conditioning-re-gasing-and-leak-detection`,
          id: `${siteUrl}/what-we-do/air-conditioning-re-gasing-and-leak-detection`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {airConditioningBanner && (
          <section>
            <ServiceHero
              title={airConditioningBanner.airConditioningBannerHeading}
              backgroundImage={
                airConditioningBanner.airConditioningBannerBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                airConditioningBanner.airConditioningBannerBackgroundImage.node
                  ?.altText
              }
              span={7}
            />
          </section>
        )}
        {airConditioningContentSection &&
          !checkPropertiesForNull(airConditioningContentSection, [
            "airConditioningContentHeading",
          ]) && (
            <section className="pt-5 pt-lg-8 position-relative">
              <Container>
                <Row>
                  <Col className="position-relative">
                    <h2 className="text-center pb-5">
                      {
                        airConditioningContentSection.airConditioningContentHeading
                      }
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        airConditioningContentSection?.airConditioningContentLeft
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        airConditioningContentSection?.airConditioningContentRight
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {airConditioningLeftImageRightContent &&
          !checkPropertiesForNull(airConditioningLeftImageRightContent, [
            "dashcamFittingRightContentHeading",
          ]) && (
            <div className="py-5 py-lg-8">
              <ImageSideContainer
                image={
                  airConditioningLeftImageRightContent.airConditioningLeftImage
                    .node?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  airConditioningLeftImageRightContent.airConditioningLeftImage
                    .node?.altText
                }
                title={
                  airConditioningLeftImageRightContent.airConditioningRightContentHeading
                }
                order="first"
                imagePadding=" pb-5 pb-lg-0    "
                text={
                  airConditioningLeftImageRightContent.airConditioningRightContentText
                }
                ctaButton={
                  airConditioningLeftImageRightContent?.airConditioningRightContentButton
                }
              />
            </div>
          )}

        <section>
          <Reviews
            title={airConditioningReviews?.airConditioningReviewsHeading}
          />
        </section>
        <section id="book" className="pb-5 pb-lg-0 pt-5 pt-lg-7">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <h2 className="text-center pb-4">
                  {airConditioningForm?.airConditioningFormHeading ||
                    "BOOK YOUR APPOINTMENT"}
                </h2>
                <div
                  style={{ background: "#F9F9F9", borderRadius: "18px" }}
                  className="p-md-5 p-4"
                >
                  <MotForm page="Air Conditioning Re-gasing And Leak Detection" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {airConditioningServicesCards &&
          !checkPropertiesForNull(airConditioningServicesCards, [
            "airConditioningServicesCardsHeading",
            "airConditioningServicesCards",
          ]) && (
            <OurServicesSection
              title={
                airConditioningServicesCards.airConditioningServicesCardsHeading
              }
              services={
                airConditioningServicesCards.airConditioningServicesCards?.nodes
              }
            />
          )}
      </Layout>
    </>
  );
};

export default ACRLDPage;
